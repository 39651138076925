import ApiService from './ApiService';

export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('/nginxApi.php?get=user');
    return data;
  }

  async getUser() {
    const { data } = await this._axios.get('/auth/rest/user');

    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/EXCAVATION');
    return data;
  }

  async getAllObjects() { // выдавать все данные не авторизованным - опция в 1.0
    const { data } = await this._axios.post('/ajax.php?action=getExcavators&v=2', {
      currentMainMenuNumber: 18,
      casse: 0
    });
    return data;
  }
}
