var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"objects"},[(_vm.isLoading)?_c('loader'):_c('div',{staticClass:"flex align-start flex-direction-column"},[_c('r-checkbox',{staticClass:"mb-8",attrs:{"title":"Завершённые работы"},model:{value:(_vm.completedWorks),callback:function ($$v) {_vm.completedWorks=$$v},expression:"completedWorks"}}),_c('div',{ref:"map",class:['objects__map', { 'objects__map--collapsed': _vm.isMapCollapsed }]},[(_vm.isShowMap)?_c('rir-map',{attrs:{"show-type":"","search":"","value":_vm.searchText,"legend":_vm.completedWorks},on:{"input":_vm.searchAddress}},[(_vm.filteredObjects.length > 0)?_c('div',_vm._l((_vm.filteredObjects),function(marker,index){return _c('div',{key:`_${marker.id}+${index}`},[((marker.details.lat && marker.details.lng) || (marker.lat && marker.lng))?_c('ymap-marker',{attrs:{"coords":marker.details.lat ? [marker.details.lat, marker.details.lng] : [marker.lat, marker.lng],"marker-id":`marker_${marker.id}+${index}`,"icon":marker.details.completed_info.isComplete ? _vm.$markerIcon('mapPinGray') : _vm.$markerIcon('mapPin'),"options":{
                hideIconOnBalloonOpen: false,
                iconColor: marker.details.completed_info.isComplete ? '#6F7A90' : '#3D75E4'
              },"cluster-name":"main","balloon":{ body: _vm.clusterBalloon({...marker,
                                                ...marker.details,
                                                id: marker.id})}}},[_c('balloon-card',{attrs:{"marker":{
                  ...marker,
                  ...marker.details,
                  id: marker.id
                }}})],1):_vm._e()],1)}),0):_vm._e()]):_vm._e(),_c('button',{staticClass:"objects__map-collapse-btn feta",on:{"click":function($event){_vm.isMapCollapsed = !_vm.isMapCollapsed}}},[_c('span',[_vm._v(" "+_vm._s(_vm.isMapCollapsed ? 'Развернуть' : 'Свернуть')+" карту ")])])],1),_c('a',{staticClass:"flex align-items-center sulguni color-rocky mt-3",attrs:{"href":'/appeals/add?categoryId=22',"target":"_blank"}},[_c('r-icon',{staticClass:"mr-2",attrs:{"size":"16","fill":"rocky","icon":"message"}}),_vm._v(" Сообщить о проведении земляных работ ")],1)],1),_c('r-modal',{ref:"modal",attrs:{"close-icon":"","fullscreen":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }