<template>
  <div class="objects">
    <loader v-if="isLoading" />
    <div
      v-else
      class="flex align-start flex-direction-column"
    >
      <r-checkbox
        title="Завершённые работы"
        class="mb-8"
        v-model="completedWorks"
      />
      <div
        :class="['objects__map', { 'objects__map--collapsed': isMapCollapsed }]"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          show-type
          search
          @input="searchAddress"
          :value="searchText"
          :legend="completedWorks"
        >
          <div v-if="filteredObjects.length > 0">
            <div
              v-for="(marker,index) of filteredObjects"
              :key="`_${marker.id}+${index}`"
            >
              <ymap-marker
                v-if="(marker.details.lat && marker.details.lng) || (marker.lat && marker.lng)"
                :coords="marker.details.lat ? [marker.details.lat, marker.details.lng] : [marker.lat, marker.lng]"
                :marker-id="`marker_${marker.id}+${index}`"
                :icon="marker.details.completed_info.isComplete ? $markerIcon('mapPinGray') : $markerIcon('mapPin')"
                :options="{
                  hideIconOnBalloonOpen: false,
                  iconColor: marker.details.completed_info.isComplete ? '#6F7A90' : '#3D75E4'
                }"
                cluster-name="main"
                :balloon="{ body: clusterBalloon({...marker,
                                                  ...marker.details,
                                                  id: marker.id})}"
              >
                <balloon-card
                  :marker="{
                    ...marker,
                    ...marker.details,
                    id: marker.id
                  }"
                />
              </ymap-marker>
            </div>
          </div>
        </rir-map>
        <button
          class="objects__map-collapse-btn feta"
          @click="isMapCollapsed = !isMapCollapsed"
        >
          <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
        </button>
      </div>
      <a
        :href="'/appeals/add?categoryId=22'"
        target="_blank"
        class="flex align-items-center sulguni color-rocky mt-3"
      >
        <r-icon
          size="16"
          fill="rocky"
          icon="message"
          class="mr-2"
        />
        Сообщить о проведении земляных работ
      </a>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import { formattedDate, wordMatch } from '../helpers/utils';
import RirMap from '../components/RirMap';
import BalloonCard from '../components/BalloonCard';
import Loader from '../components/Loader';

export default {
  name: 'ObjectList',
  components: {
    BalloonCard,
    Loader,
    RirMap
  },
  data() {
    return {
      isMapCollapsed: false,
      selectedSortType: 'ALL',
      searchText: null,
      timeoutId: null,
      isShowMap: false,
      completedWorks: false
    };
  },
  computed: {
    BalloonCard() {
      return BalloonCard;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    filteredObjects() {
      let list = this.$store.state.allObjects;

      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el.details?.address || '', this.searchText) <= 0.5
        );
      }
      if (!this.completedWorks) {
        list = list.filter(el => !el.isDone || false);
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.$store.dispatch('getAllObjects');
    await this.$store.dispatch('getUserAccesses');
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(date);
    },
    clusterBalloon(marker) {
      return `<div class="pa-4"><a href="${this.$store.getters.getUserAccess ? `/admin/excavation/work?id=${marker.id}` : '#'}"
    class="r-map-balloon pointer"
  >
    <p class="roquefort mb-1">
      ${marker.description || '—'}
    </p>
    <p class="сaprino opacity-72">
      ${marker.address || '—'}
    </p>
    <div
      class="flex align-start mt-3 mozzarella"
    >
    <i class="RIcon icons mr-2 icon-placeholder rir-calendar_16" style="color: var(--rir-rocky);"></i>
      ${
  `${this.getFormattedDate(marker.dateFrom) || marker.dateFrom || 'Не указано'
  } — ${this.getFormattedDate(marker.dateToSumm) || marker.dateToSumm || 'Не указано'
  }${marker.completed_info.isComplete ? ', работы завершены' : ''}`
}
    </div>

   <div
      class="flex align-start mt-3 mozzarella"
    >
    <i class="RIcon icons mr-2 icon-placeholder rir-mayor_16" style="color: var(--rir-rocky);"></i>

      ${
  marker.firmName || `${marker.applicantName} ${marker.applicantSurname} ${marker.applicantPatronymic}`
}
    </div>
    <div class="mt-3">
      <a
        href="/appeals/add?categoryId=22"
        target="_blank"
        class="flex align-items-center sulguni color-rocky opacity-72"
      >
      <i class="RIcon icons mr-2 icon-placeholder rir-send_16" style="color: var(--rir-rocky);"></i>

        Сообщить о нарушении
      </a>
    </div>
  </a>
 </div>`;
    },
    searchAddress(e) {
      this.searchText = e;
    },
    onChangeType(type) {
      this.selectedSortType = type.id;
    },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },
    onMapClick() {
      this.selectedMarkerId = null;
    },
    onBalloonClick() {
      if (!this.selectedMarkerId) return;

      this.$router.push({
        name: 'Edit',
        params: { id: this.selectedMarkerId }
      });
    },
    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    }
  }
};
</script>

<style lang="scss" scoped>
.objects {

  &__list {
    width: 41.7%;
  }

  &__map--wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }

  &__map {
    position: relative;
    display: flex;
    height: 648px;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    transition: 0.4s all;
  }

  &__map-collapse-btn {
    display: none;
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}

@media screen and (max-width: 1023px) {
  .objects {

    &__cards {
      width: 100%;
    }

    &__map-cont {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }

    &__map {
      height: 447px;
      margin-bottom: 44px;
    }

    &__map--collapsed {
      height: 112px;
    }

    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;

      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .objects {
    &__input-search {
      display: none;
      // position: absolute;
      // z-index: 10;
      // background-color: #fff;
      // box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
      // top: 16px;
      // left: 16px;
    }
  }
}

@media screen and (max-width: 599px) {
  .objects {
    &__map--collapsed {
      height: 144px;
    }
  }
}

.add {
  margin-bottom: 32px;
  height: 80px;
  margin-top: 12px;
  background: #F9F6FE;
  border-radius: 16px;

  &__text {
    width: 50%;
    margin-left: 24px;
    display: table;
    float: left;
  }

  &__bottom {
    cursor: pointer;
    margin-top: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #9954F2;
  }

  &__title {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #21043E;
  }

  &__image {
    float: right;
    margin-right: 24px;
    margin-top: -32px;
  }
}

@media screen and (max-width: 599px) {
  .add {
    &__text {
      width: 100%;
    }

    &__image {
      display: none;
    }
  }
}
</style>
