import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';
import { getNumberOfDays } from '../helpers/utils';

const api = new Api();
Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    allObjects: [],
    isLoading: true,
    userAccess: {}
  }),
  mutations: {
    setAllObjects(state, res) {
      const data = {
        Description: '',
        completed_info: {
          isComplete: false,
          date: null,
          completed_type: '', // 'AUTO' - автоматически или 'MANUALLY' вручную,
          completed_count: 0
        },
        reports: [],
        endPerDate: false,
        images: [],
        dateFrom: null, // Общий срок (начало)
        dateTo: null, // Общий срок (окончание)
        address: null, // Адрес разрытия
        description: null, // Описание работы

        // Ордер
        woOrder: false, // Без ордера
        isIncident: false, // Авария
        orderWriteNumber: null, // Номер приказа
        orderDate: null, // Дата приказа
        orderNo: null, // № ордера
        continuationWorkOrderNo: null, // № приказа продление сроков работ
        continuationWorkOrderDate: null, // Дата приказа продление сроков работ
        orderContinue: null, // Продление работ до
        endWorkOrderNo: null, // № приказа закрытия работ
        endWorkOrderDate: null, // Дата приказа закрытия работ

        applicantStatus: null,
        isPetitionerIndividual: false,
        // ContactIndividual: null, // ФИО заявителя (Физ. лицо)
        applicantSurname: null, // фамилия заявителя
        applicantName: null, // имя заявителя
        applicantPatronymic: null, // отчество заявителя
        applicantAddress: null, // aдрес заявителя (Физ. лицо)
        applicantEmail: null,
        inn: null, // ИНН
        firmAccount: null, // Расчетный счет
        bank: null, // Банк
        firmId: null, // id организации
        firmName: null, // Наименование организации
        isAddressesMatch: false, // Совпадение юр. и почтового адреса
        firmAddress: null, // Адрес организации (Юридический) ???
        postAddress: null, // Почтовый адрес организации
        firmDir: null, // ФИО руководителя организации
        firmPhone: null, // Тел. ответственного   ???

        // Рекультивация и восстановление
        // revegetationWorks: null, // Работы по восстановлению ???
        // revegetationRoad: null, // Проезжая часть ???
        // revegetationSidewalk: null, // Тротуар ???
        // revegetationTile: null, // Плитка ???
        // revegetationGrass: null, // Газон ???
        // guarantee: null, // Гарантийный срок устранения дефектов

        dateFrom2: null, // Работы по восстановлению
        dateTo2: null, // Работы по восстановлению
        dateFrom3: null, // Даты производства работ
        dateTo3: null, // Даты производства работ
        dateContinuedFrom: null, // Даты продления производства работ
        dateContinuedTo: null, // Даты продления производства работ

        // Восстановление
        revegetation: null, // Описание
        revegetationRoad: null, // Дорога
        revegetationTiles: null, // Плитка
        revegetationTree: null, // Газон
        revegetationFootway: null, // Тротуар

        // Гарантийный срок
        guarantee: null,
        // Благоустройства
        improvements: [],
        // Габариты разрытия
        length: null, // Длина разрытия
        width: null, // Ширина разрытия
        depth: null, // Глубина разрытия

        methodOfWork: null, // Способ ведения зем. работ

        wareHouseAddress: null, // Адрес складирования ???
        ownerDeadline: null, // Срок исполнительной съемки
        isExtraSoil: false, // Излишний грунт ???
        isSastes: false, // Отходы ???
        dateFromFence: null,
        dateToFence: null,
        lat: null,
        lng: null
      };
      const checkDate = (el, days) => {
        const dateEnd = el.details?.dateContinuedTo ? el.details?.dateContinuedTo : el.details.dateTo;
        if (getNumberOfDays(dateEnd) <= days && !el.details.completed_info?.isComplete) {
          return { flag: true };
        }
        return { flag: false };
      };
      state.allObjects = res.map(el => {
        const camelCaseObj = {};
        for (const elem in el.details) {
          if (elem === 'INN') {
            camelCaseObj[elem.toLowerCase()] = el.details[elem];
          } else {
            camelCaseObj[elem.split('')[0].toLowerCase() + elem.slice(1)] = el.details[elem];
          }
        }
        return {
          ...el,
          isDone: el.details?.completed_info?.isComplete || !!el.dateClosed || false,
          details: {
            ...data,
            ...camelCaseObj
          }
        };
      });
      console.log(state.allObjects);
    },
    setUserAccess(state, res) {
      state.userAccess = res;
    },
    setLoading(state, bool) {
      state.isLoading = bool;
    }
  },
  getters: {
    getUserAccess(state) {
      return state.userAccess?.access?.view || false;
    }
  },
  actions: {
    async getAllObjects(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getAllObjects();
      ctx.commit('setAllObjects', res.all);
      ctx.commit('setLoading', false);
      return res;
    },
    async getUserAccesses(ctx) {
      const res = await api.getUserAccesses();
      ctx.commit('setUserAccess', res);
      return res;
    }
  },
  modules: {}
});
